.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.first{
  display:flex;
  background-color: #6d727b;
  color: aliceblue;
}
.callsymbol{
  width:20px;
height: 20px;
margin-top: 20px;
margin-left: 50px;
margin-right: 10px;
}
.phno
{
font-size: 10px;
margin-top: 10px;
}
.fax{
  margin-left: 20px;
}
.cet
{
  margin-left: 20px;
}
.research{
margin-left:500px;
}
.alumni{
  margin-left: 10px;
}
.stjsymbol{
  width: 500px;

}
.second{
  display:flex;
  background-color: rgb(138, 171, 127);
  color: aliceblue;
}
.home{
  margin-left: 30px;
  margin-top: 40px;
}
.home1{
  margin-left: 10px;
  margin-top: 40px;
}
.home2{
  margin-top: 40px;
  margin-left: 10px;
}
.About{
  background-color:rgb(138, 171, 127);
  border: none;
}
.clgbrown{
width: 1140px;
height:500px;
}
.wel{
  color: rgb(45, 81, 148);
  font-size: 50px;
  background-color: rgb(223, 235, 219);
}
.stj1{
  margin-left: 50px;
  margin-right: 20px;
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.stj2{
  margin-left: 50px;
  margin-right: 20px;
  width: 250px;
  height: 250px; 
  border-radius: 10px;
}
.stj3{
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.stj4{
  width: 250px;
  height: 250px;
  border-radius: 10px;
}
.join{
display: flex;
}
.swami
{
width: 250px;
height: 525px;
margin-right: 20px;
border-radius: 10px;
}
.stj12{
  margin-left: 160px;
}
.commitment{
  font-size: 50px;
  color: rgb(45, 81, 148);
}
.Commitment
{
  background-color:rgb(223, 235, 219);
  
}
.textnit{
  display: flex;
  text-align: justify;
  margin-left: 50px;
  
}
.imgnit{
  margin-left: 30px;
  width:500px;
  height:320px;
  margin-right: 50px;
  margin-top: 10px; 
  border-radius: 10px;
}
.vision{
  font-size: 50px;
  color: rgb(45, 81, 148);
  background-color: rgb(223, 235, 219);
}
.vistext{
  font-size: 20px;
  margin-top: 2px;
  
}
.mission{
  font-size: 50px;
  color: rgb(45, 81, 148);
  background-color: rgb(223, 235, 219);
}
.our{
  font-size: 50px;
  color: rgb(45, 81, 148);
  background-color: rgb(223, 235, 219);
  margin-bottom: 20px;

}
.ourtext{
  font-size: 50px;
}
.Our{
  display: flex;
  margin-bottom: 100px;
  margin-right:250px;
}
.ourtext1{
  font-size: 20px;
}
.a1{
margin-left: 280px;
background-color: rgb(226, 205, 177);
border-radius: 150px;
height: 140px;
width: 300px;
}
.a2{
  margin-left: 40px;
  background-color: rgb(226, 205, 177);
  border-radius: 150px;
  height: 140px;
  width: 300px;
}
.Viewmore
{
  margin-top: 50px;
  background-color: #5673a8;
  color: aliceblue;
  border-radius: 20px;
  border: #e3e7ee;
  font-size: 50px;
}
.lathead{
  color: rgb(45, 81, 148);
  font-size: 50px;
  background-color: rgb(223, 235, 219);
}

.latimage{
  width: 30%;
  margin-left: 80px;
  border-radius: 20px;
}
.latimage:hover{
  scale:1.1;
  transition: ease-in-out 1s;
  }
.latflex{
display: flex;
margin-top: 30px;

}
.lat-text{
  margin-top: 20px;
  padding: 30px;
}
.latallign{
font-size: 30px;
color: #4e648e;
margin-bottom: 30px;
}
.latimage1{
  width: 30%;
  border-radius: 20px;
}
.latimage1:hover{
scale:1.1;
transition: ease-in-out 1s;
}
.lat-text1{ 
  padding: 30px;
  margin-top: 20px;
}
.latallign1{
  font-size: 30px;
  color: #4e648e;
  margin-bottom: 20px;
  }